/* FeatureItem.css */
.feature-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 20px;
    background: #4A90E2;
    border-radius: 10px;
    max-width: 100%;
    margin-bottom: 20px;
  }
  
  .icon img {
    width: 60px;
    height: auto;
    margin-right: 20px;
  }
  
  .text-content h3 {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #F4F7FA;
  }
  
  .text-content p {
    font-size: 1rem;
    color: #F4F7FA;
    opacity: 0.8;
  }
  