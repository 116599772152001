/* Footer.css */

.footer {
  background-color: #ffffff; /* Set a light background color */
  color: #000; /* Set text color to black */
  padding: 40px 20px;
  text-align: center;
}

.footer-top {
  margin-bottom: 40px;
}

.footer-top h2 {
  font-size: 28px; /* Increase font size for better readability */
  font-weight: bold; /* Make the text bold */
  margin: 0 0 20px;
  color: #111; /* Ensure the heading is clearly visible */
}

.cta-button {
  background-color: black;
  color: white;
  border: none;
  padding: 12px 24px; /* Adjust padding for better button size */
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for a glowing effect */
}

.cta-button:hover {
  background-color: #333;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4); /* Stronger shadow effect on hover */
}

.footer-middle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
  max-width: 1200px; /* Limit the width to align items properly */
  margin: 0 auto; /* Center the middle section */
}

.footer-logo h3 {
  font-size: 24px;
  font-weight: bold;
  color: #000; /* Make the logo color black */
  text-align: left; /* Align the logo to the left */
}

.footer-nav {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 30px; /* Add more spacing between nav items */
}

.footer-nav li {
  display: inline-block;
}

.footer-nav a {
  text-decoration: none;
  color: black;
  font-weight: bold; /* Make the navigation links bold */
}

.contact-us {
  text-align: left;
}

.contact-us h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-us p {
  margin: 5px 0;
  font-size: 16px;
}

.social-icons a {
  margin: 0 10px;
  color: black;
  font-size: 20px; /* Increase icon size for better visibility */
}

.social-icons a:hover {
  color: #333; /* Slight color change on hover */
}

.footer-bottom {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin-top: 20px;
}

.footer-bottom p {
  margin: 0;
  color: #777; /* Use a lighter color for the copyright text */
}

.footer-links {
  list-style: none;
  display: flex;
  gap: 15px;
  padding: 0;
  justify-content: center;
}

.footer-links a {
  text-decoration: none;
  color: #777;
  
}

.waitlist-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff; /* White background for the container */
  border: none;
  border-radius: 30px; /* Rounded edges */
  padding: 10px 20px;
  max-width: 600px;
  margin: 0 auto 20px;
  position: relative; /* Position relative for the pseudo-elements */
  z-index: 1; /* Ensure the container content is above the glow effect */
}

.waitlist-container::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(-29deg, #1e90ff -9%, #1e90ff 99%);
  transform: translate3d(2px, 0px, 0) scale(1.05);
  filter: blur(20px);
  opacity: 0.7;
  transition: opacity 0.3s;
  border-radius: inherit; /* Ensure the glow matches the container's shape */
}

.waitlist-container::after {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  border-radius: inherit; /* Prevents issues with parent stacking context */
}

.waitlist-input {
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  padding: 10px;
  flex: 1;
  color: #555;
}

.waitlist-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px; /* Rounded button */
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.waitlist-button:hover {
  background-color: #333; /* Slightly darker on hover */
}

.footer-nav-button {
  background: none;
  border: none;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
  font-size: 1.1rem; /* Increase font size for better readability */
  transition: background 0.3s ease, color 0.3s ease;
}

.footer-nav-button:hover {
  color: #333; /* Change color slightly on hover for visual feedback */
}

.footer-nav {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 30px; /* Spacing between footer navigation items */
  margin: 20px 0; /* Add margin to separate from other sections */
}

.footer-links .footer-nav-button {
  font-size: 1rem;
  color: #777; /* Lighter color for links in footer-bottom */
}

.footer-links .footer-nav-button:hover {
  color: #000; /* Darker color on hover for better contrast */
}
