/* FAQItem.css */
.faq-item {
  background: #fff;
  margin-bottom: 10px;
  border-radius: 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #e6e6e6;
  transition: box-shadow 0.3s;
}

.faq-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.faq-question {
  width: 100%;
  padding: 20px 24px;
  background: #F4F7FA;
  color: black;
  border: none;
  text-align: left;
  font-size: 1.3em;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: background 0.3s, box-shadow 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question:hover {
  background: #E8F0FA;
  transform: translateY(-2px);
}

.faq-question::after {
  content: '';
  width: 32px;
  height: 32px;
  background: #535bf2;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M7 10l5 5 5-5H7z"/%3E%3C/svg%3E');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  transition: transform 0.3s, background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq-item.active .faq-question::after {
  transform: rotate(180deg);
}

.faq-answer {
  display: none;
  padding: 20px 24px;
  font-size: 1.1em;
  color: #777;
  background: #F4F7FA;
  line-height: 1.6;
  border: none;
  margin: 0;
}

.faq-item.active .faq-answer {
  display: block;
  padding-top: 0;
}