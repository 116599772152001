:root {
    --main-gradient: linear-gradient(180deg, #F4F7FA 0%, #729EE1 100%);

    --btn-bg-color: #fff;
    --btn-hover-bg-color: #e0e0e0;
    --btn-text-color: #334b8c;
    --section-padding: 60px 20px;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    background: #F4F7FA;
    color: #4A90E2;
    text-align: center;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.9;
    background: #F4F7FA;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 600;
    font-size: 16px;
    box-sizing: border-box;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #4A90E2;
}

.logo-image {
    height: 60px;
    margin-right: 5px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-links {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.nav-links li {
    display: inline;
}

.nav-links a {
    text-decoration: none;
    color: #4A90E2;
    font-weight: bold;
    padding: 5px;
    transition: background 0.3s;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.hamburger span {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 2px 0;
    transition: 0.4s;
}

.nav-button {
    background: none;
    border: none;
    color: #4A90E2;
    font-weight: bold;
    cursor: pointer;
    padding: 5px;
    text-decoration: none;
    transition: background 0.3s;
    font-size: 1.15rem; 
}

.nav-button:hover {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}


@media screen and (max-width: 768px) {
    .nav-links {
        display: none;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 80px;
        left: 0;
        background: #F4F7FA;
        padding: 20px 0;
    }

    .nav-links.active {
        display: flex;
    }

    .nav-links li {
        margin: 10px 0;
    }

    .hamburger {
        display: flex;
    }

    .right-section {
        display: none;
    }
}

.nav-links a:hover {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

.right-section {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #4A90E2;
}

.btn-demo-wait {
    padding: 15px 30px;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    background: #4A90E2;
    color: #F4F7FA;
    transition: background 0.3s;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.btn-demo {
    padding: 20px 40px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    background: var(--btn-bg-color);
    color: var(--btn-text-color);
    transition: background 0.3s;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.btn-demo:hover {
    background: var(--btn-hover-bg-color);
}

#hero {
    background-color: #F4F7FA;
    padding: 80px 20px 40px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-sizing: border-box;
}

#hero h1 {
    color: #4A90E2;
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}

#hero p {

    max-width: 1000px;
    font-weight: 600;
    opacity: 0.7;
    font-size: 1.2rem;
    margin-bottom: 30px;
    text-align: center;
    color: black;
}

.input-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    background: #90b8f3;
    padding: 10px;
    border-radius: 15px;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.email-input {
    padding: 14px;
    border: none;
    outline: none;
    border-radius: 25px;
    flex: 1;
    background-color: #90b8f3;
    font-size: 1rem;
    color: #666;
    min-width: 0;
}

.btn-demo {
    padding: 20px 20px;
    border: none;
    border-radius: 25px;
    color: #5C6AC4;
    font-size: 1rem;
    cursor: pointer;
    white-space: nowrap;
}

.hero-images {
    display: flex;
    justify-content: center;
    max-width: 100%;
    padding: 0px 0;
    margin: 0;
}

.mockup-image {
    padding: 0;
    margin: 0;
    max-width: 100%;
    border-radius: 15px;
}

.privacy-text {
    padding-top: 20px;
    text-align: center;
    font-size: 1rem;
    color: #4A90E2;
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    #hero h1 {
        font-size: 3rem;
    }

    #hero p {
        font-size: 1rem;
    }

    .input-container {
        max-width: 90%;
    }

    .email-input {
        font-size: 0.9rem;
    }

    .btn-demo {
        font-size: 0.9rem;
        padding: 14px 15px;
    }
}

@media (max-width: 480px) {
    #hero {
        padding: 60px 15px 30px;
    }

    #hero h1 {
        font-size: 2.5rem;
    }

    .hero-images {
        max-width: 100%;
    }

    .input-container {
        flex-direction: column;
        gap: 15px;
    }

    .email-input,
    .btn-demo {
        width: 100%;
        font-size: 1rem;
    }
}

.finguru-wrapper {
    padding: var(--section-padding);
    display: flex;
    justify-content: center;
    background: #F4F7FA;
}

.finguru-section {
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    color: #4A90E2;
    gap: 60px;
    width: 100%;
}

.finguru-content {
    flex: 1;
    text-align: left;
    max-width: 45%;
}

.finguru-content h1 {
    font-size: 4rem;
    font-weight: bold;
    margin-top: 50px;
    color: #4A90E2;
}

.finguru-content h2 {
    opacity: 0.7;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: black;
}

.finguru-features {

    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
}

.feature-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    padding: 20px;
    background: #4A90E2;
    border-radius: 10px;
    max-width: 100%;
}

.icon img {
    width: 60px;
    height: auto;
    margin-right: 20px;
}

.feature-item h3 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #F4F7FA;
}

.feature-item p {
    font-size: 1.2rem;
    opacity: 0.7;
    margin: 0;
    color: #F4F7FA;
}

@media (max-width: 1024px) {
    .finguru-section {
        flex-direction: column;
        align-items: center;
    }

    .finguru-content {
        max-width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    .finguru-content h1 {
        font-size: 60px;
    }

    .finguru-content h2 {
        font-size: 2.5em;
    }
}

@media (max-width: 768px) {
    .finguru-content h1 {
        font-size: 50px;
    }

    .finguru-content h2 {
        font-size: 2em;
    }

    .feature-item {
        flex-direction: column;
        text-align: center;
    }

    .icon img {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

@media (max-width: 480px) {
    .finguru-content h1 {
        font-size: 40px;
    }

    .finguru-content h2 {
        font-size: 1.5em;
    }

    .feature-item h3 {
        font-size: 1.5em;
    }
}

/* Styles for fimInsights Section */
.fim-insights-wrapper {
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F4F7FA;
}

.fim-insights-title {
    font-size: 4rem;
    color: #4A90E2;
    margin-bottom: 20px;
    font-weight: bold;
}

.fim-insights-subtitle {
    font-size: 2rem;
    color: black;
    font-weight: bold;
    opacity: 0.7;
    margin-bottom: 40px;
    text-align: center;
}

.fim-insights-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.fim-insights-card {
    background: #5C6AC4;
    width: 300px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 30px;
    padding-bottom: 0;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    transition: transform 0.3s;
}

.fim-insights-card:hover {
    transform: translateY(-5px);
}

.fim-insights-card img {
    width: 100%;
    border-radius: 20px;
    margin-bottom: 15px;
}

.fim-insights-card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.fim-insights-card p {
    font-size: 1em;
    opacity: 0.9;
}

/* Specific Background Colors for Individual Cards */
.fim-insights-card.green {
    background: #A4D86F;
}

.fim-insights-card.pink {
    background: #D864D8;
}

/* FAQ Section */

.faq-wrapper {
    padding: var(--section-padding);
    background: #F4F7FA;
    text-align: left;
    color: #333;
}

.faq-title {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 40px;
    text-align: center;
    color: #4A90E2;
}

.faq-container {
    max-width: 1000px;
    margin: 0 auto;
}

.faq-item {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 12px;
    /* Slight border radius */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    overflow: hidden;
    border: 1px solid #e6e6e6;
    /* Light border */
    transition: box-shadow 0.3s;
}

.faq-item:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    /* Hover shadow effect */
}

.faq-question {
    width: 100%;
    padding: 20px 24px;
    /* Increased padding */
    background: #F4F7FA;
    /* Ensured background matches */
    color: black;
    /* Updated question font color */
    border: none;
    text-align: left;
    font-size: 1.3em;
    /* Increased font size */
    font-weight: bold;
    /* Make it bold */
    cursor: pointer;
    outline: none;
    transition: background 0.3s, box-shadow 0.3s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question:hover {
    background: #E8F0FA;
    /* Change background on hover */
    transform: translateY(-2px);
    /* Move button up on hover */
}

.faq-question::after {
    content: '';
    width: 32px;
    /* Size of the button */
    height: 32px;
    /* Size of the button */
    background: #535bf2;
    /* Background color */
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white"%3E%3Cpath d="M7 10l5 5 5-5H7z"/%3E%3C/svg%3E');
    /* White arrow icon */
    background-size: 70%;
    /* Adjust icon size */
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
    /* Rounded corners */
    transition: transform 0.3s, background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
}


.faq-item.active .faq-question::after {
    transform: rotate(180deg);
    /* Rotate arrow when active */
}


.faq-answer {
    display: none;
    padding: 20px 24px;
    /* Match padding */
    font-size: 1.1em;
    /* Increased font size */
    color: #777;
    /* Grey shade for the answer text */
    background: #F4F7FA;
    /* Ensure the background matches */
    line-height: 1.6;
    /* Improved readability */
    border: none;
    /* Remove all borders */
    margin: 0;
    /* Remove any margin */
}

.faq-item.active .faq-answer {
    display: block;
    /* Display the answer when active */
    padding-top: 0;
    /* Remove padding to make seamless */
}

/* Main footer styles */


.footer {
    background-color: #4A90E2;
    padding: 20px 0;
    color: white;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-content {
    display: flex;
    flex-direction: column;
}

.info-boxes {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.info-box {
    flex: 1;
    margin: 10px;
    padding: 20px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 280px;
}

.info-box:nth-child(1) {
    background-color: #F4F7FA;
}

.info-box:nth-child(2),
.info-box:nth-child(3) {
    background-color: #F4F7FA;
}

.info-box span {
    display: block;
    margin-top: 10px;
    font-size: 1.2rem;
    color: black;
    /* Updated info box text color */
}

.highlight {
    color: #ff6b6b;
}

.social-icons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.social-icons a {
    color: white;
    font-size: 20px;
    background-color: #0077b5;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-icons a:nth-child(1) {
    background-color: #0077b5;
}

.social-icons a:nth-child(2) {
    background-color: #3b5998;
}

.social-icons a:nth-child(3) {
    background-color: #0077b5;
}

.social-icons a:nth-child(4) {
    background-color: #ff0000;
}

.social-icons a:nth-child(5) {
    background-color: #1da1f2;
}

.large-icon {
    position: absolute;
    top: -40px;
    right: -15px;
    width: 90px;
    height: 90px;
}

.transparent {
    color: #ff69b4;
}

.jupiter-section {
    text-align: center;
    margin-top: 20px;
}

.jupiter-title {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 10px;
}

.play-store-button {
    display: inline-block;
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
}

.play-store-button i {
    margin-right: 10px;
}

.app-store-buttons img {
    height: 50px;
    max-width: 100%;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .info-box {
        flex: 1 1 45%;
        /* Adjust to show two boxes per row */
    }

    .social-icons a {
        width: 30px;
        height: 30px;
        font-size: 18px;
    }

    .large-icon {
        width: 60px;
        height: 60px;
    }

    .jupiter-title {
        font-size: 40px;
    }
}

@media (max-width: 768px) {
    .info-boxes {
        flex-direction: column;
        align-items: center;
    }

    .info-box {
        width: 90%;
        /* Make the boxes take up most of the screen width */
        margin: 10px 0;
    }

    .social-icons {
        flex-wrap: wrap;
        gap: 10px;
    }

    .jupiter-title {
        font-size: 30px;
    }

    .app-store-buttons img {
        height: 45px;
    }
}

@media (max-width: 480px) {
    .footer-container {
        padding: 0 10px;
    }

    .info-box {
        padding: 15px;
    }

    .social-icons a {
        width: 25px;
        height: 25px;
        font-size: 16px;
    }

    .large-icon {
        width: 50px;
        height: 50px;
    }

    .jupiter-title {
        font-size: 24px;
    }

    .app-store-buttons img {
        height: 40px;
    }

    .play-store-button {
        padding: 8px 15px;
        font-size: 12px;
    }
}

.weird-search-container {
    background-color: rgba(74, 144, 226, 0.1);
    /* Very light blue */
    border-radius: 20px;
    display: flex;
    align-items: center;
    width: 500px;
    /* Adjust as needed */
    height: 60px;
    /* Adjust as needed */
    padding: 0 16px;
    /* Horizontal padding */
    border: 1px solid #4a90e2;
    /* Thin blue border */
}

.weird-email-input {
    border: none;
    background-color: transparent;
    color: #4a90e2;
    /* Blue text color */
    padding: 0 16px;
    /* Horizontal padding */
    flex: 1;
    /* Take available space */
    font-size: 1.2rem;
    /* Font size for input */
    height: 100%;
    outline: none;
    /* Removes the focus outline */
}

.weird-email-input::placeholder {
    color: rgba(0, 0, 0, 0.5);
    /* Placeholder color */
}

.weird-demo-button {
    background-color: #ffffff;
    /* White background for button */
    border: 1px solid #4a90e2;
    /* Blue border for button */
    border-radius: 20px;
    /* Rounded corners */
    color: #4a90e2;
    /* Blue text color */
    padding: 12px 24px;
    /* Button padding */
    font-size: 1rem;
    /* Font size for button */
    font-weight: bold;
    /* Bold font */
    cursor: pointer;
    margin-left: 16px;
    /* Space between input and button */
    transition: background-color 0.3s;
    /* Smooth transition */
}

.weird-demo-button:hover {
    background-color: #e3f2fd;
    /* Light blue on hover */
}

.weird-demo-button:focus {
    outline: none;
    /* Removes the focus outline */
}

/* UPI Payments Section */
.upi-container {
    display: flex;
    min-height: 100vh;
    padding: 2rem;
    box-sizing: border-box;
    background-color: #F4F7FA;
    /* Background color */
    position: relative;
    /* Required for absolute positioning of icons */
    max-width: 80%;
    /* Maximum width set to 80% */
    margin: 0 auto;
    /* Centering the container */
}

.upi-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    /* Bring content above icons */
}

h6 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    /* Increased space below heading */
    align-self: start;
    max-width: 50%;
}

.upi-highlight {
    color: #4A90E2;
    /* Highlight color for emphasis */
}

.upi-list {
    align-self: start;
    list-style-type: none;
    padding: 0;
    font-size: 1.1rem;
}

.upi-list-item {
    margin-bottom: 1rem;
    display: flex;
    align-items: start;
    max-width: 70%;
}

.upi-list-item::before {
    content: "✓";
    color: #4A90E2;
    /* Checkmark color */
    margin-right: 1rem;
    font-size: 1.3rem;
}

.upi-cta-button {
    background-color: #4A90E2;
    /* Button background color */
    color: #fff;
    /* Button text color */
    border: none;
    padding: 1rem 2rem;
    /* Button padding */
    font-size: 1.1rem;
    /* Button font size */
    border-radius: 5px;
    /* Button border radius */
    cursor: pointer;
    /* Pointer on hover */
    margin-top: 2rem;
    /* Space above button */
    align-self: flex-start;
    /* Align button to the left */
}

.upi-icons-container {
    position: absolute;
    /* Absolute positioning for icons */
    top: 0;
    right: 0;
    width: 50%;
    /* Width of icon container */
    height: 100%;
    /* Full height */
    z-index: 1;
    /* Send icons behind content */
}

.upi-icon {
    border-radius: 50%;
    /* Circular icons */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* Positioning of individual icons */
    width: 220px;
    /* Default icon size */
    height: 220px;
    /* Default icon size */
}

.upi-icon img {
    width: 80%;
    /* Image takes 80% of the container */
    height: auto;
    /* Image takes full height */
    object-fit: cover;
    /* Keep images circular */
}

.upi-icon-green {
    background-color: #1a472a;
    /* Background color for green icons */
}

.upi-icon-blue {
    background-color: #1a2a47;
    /* Background color for blue icons */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .upi-icon {
        width: 180px;
        /* Smaller icon size for medium screens */
        height: 180px;
        /* Smaller icon size for medium screens */
    }

    .upi-list-item {
        max-width: 50%;
    }
}

@media (max-width: 992px) {
    .upi-content {
        max-width: 60%;
        /* Increase width for content */
    }

    .upi-icons-container {
        width: 40%;
        /* Decrease width for icons */
    }

    .upi-icon {
        width: 150px;
        /* Icon size for smaller screens */
        height: 150px;
        /* Icon size for smaller screens */
    }

    h6 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
        max-width: 90%;
    }

    .upi-list {
        font-size: 1rem;
    }

    .upi-list-item {
        max-width: 70%;
    }
}

@media (max-width: 768px) {
    .upi-container {
        flex-direction: column;
        padding: 1rem;
    }

    .upi-content {
        max-width: 100%;
    }

    .upi-icons-container {
        position: relative;
        width: 100%;
        height: 400px;
        margin-top: 2rem;
    }

    .upi-icon {
        width: 120px;
        height: 120px;
    }

    h6 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        max-width: 90%;
    }

    .upi-list-item {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .upi-list-item {
        max-width: 100%;
    }

    h6 {
        font-size: 1.5rem;
        margin-bottom: 0.8rem;
        max-width: 90%;
    }

    .upi-list-item {
        font-size: 0.9rem;
    }

    .upi-cta-button {
        font-size: 1rem;
        padding: 0.8rem 1.5rem;
    }

    .upi-icons-container {
        height: 300px;
    }

    .upi-icon {
        width: 100px;
        height: 100px;
    }
}