.carousel-wrapper {
    position: relative;
   
    height: 100px; /* Adjust as needed */
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 40px;
}

.carousel-container {
    
    height: 100%;
    overflow: hidden;
}

.carousel-track {
    display: flex;
    align-items: center;
    animation: scroll 40s linear infinite; /* Adjust speed as needed */
    width: max-content; /* Ensure it grows as we clone */
}

.carousel-item {/* Fixed size for testing */
    height: 80px; /* Fixed size for testing */
    margin: 0 30px;
}



@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}

/* Smooth Blur Edges */
.blur-edge-left, .blur-edge-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100px;
    z-index: 1;
    pointer-events: none;
}

.blur-edge-left {
    left: 0;
    opacity: 0.5;
}

.blur-edge-right {
    right: 0;
    opacity: 0.5;
}