/* FimInsightsCard.css */
.fim-insights-card {
    background: #5C6AC4;
    width: 300px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    transition: transform 0.3s;
    margin-bottom: 20px;
  }
  
  .fim-insights-card:hover {
    transform: translateY(-5px);
  }
  
  .fim-insights-card img {
    width: 100%;
    border-radius: 20px;
    margin-top: 15px;
  }
  
  .fim-insights-card h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .fim-insights-card p {
    font-size: 1rem;
    opacity: 0.9;
  }
  
  /* Specific Background Colors for Individual Cards */
  .fim-insights-card.green {
    background: #A4D86F;
  }
  
  .fim-insights-card.pink {
    background: #D864D8;
  }
  